import {
	Modal as MantineModal
} from "@mantine/core";

import { useGetDeviceType } from "_common/hooks/global";

import useStyles from "./styles";

const component = props => {
	const {
		classNames,
		children,
		...rest
	} = props;

	const { classes, cx } = useStyles ();
	const deviceType      = useGetDeviceType ();

	return (
		<MantineModal
			classNames = { {
				root    : cx ( classes.modalRoot, classNames?.root ),
				body    : cx ( classes.modalBody, classNames?.body ),
				header  : cx ( classes.modalHeader, classNames?.header ),
				title   : cx ( classes.modalTitle, classNames?.title ),
				content : cx ( classes.modalContent, classNames?.content ),
				close   : cx ( classes.modalClose, classNames?.close ),
				inner   : cx ( classes.modalInner, classNames?.inner ),
				overlay : cx ( classes.modalOverlay, classNames?.overlay )
			} }
			fullScreen = { deviceType === "mobile" }
			{ ...rest }
		>
			{children}
		</MantineModal>
	);

};

component.Root        = MantineModal.Root;
component.Overlay     = MantineModal.Overlay;
component.Content     = MantineModal.Content;
component.Header      = MantineModal.Header;
component.Title       = MantineModal.Title;
component.CloseButton = MantineModal.CloseButton;
component.Body        = MantineModal.Body;

export default component;
