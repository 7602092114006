import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		modalRoot: {

		},
		modalBody: {
			padding: "0"
		},
		modalHeader: {
			borderBottom : `2px solid ${ theme.other.colors.secondaryGray }`,
			padding      : "1.2rem"
		},
		modalTitle: {
			fontWeight : "bold",
			fontSize   : theme.fontSizes.lg
		},
		modalContent: {
			height       : "max-content",
			borderRadius : "1rem"
		},
		modalClose: {
			transform : "scale(2)",
			color     : "black"
		},
		modalInner: {

		},
		modalOverlay: {

		}
	};
} );