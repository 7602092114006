import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		drawerContent: {
			height            : "fit-content",
			borderRadius      : "1.6rem 1.6rem 0 0",
			padding           : "2rem",
			paddingBlockStart : "4rem",
			background        : theme.other.colors.dialog.primary,
			textAlign         : "center"
		},
		modalContent: {
			flexBasis         : "min(400px, 90%)",
			padding           : "2rem",
			paddingBlockStart : "4rem",
			background        : theme.other.colors.dialog.primary,
			textAlign         : "center"
		},
		closeButton: {
			position : "absolute",
			top      : "2rem",
			right    : "2rem"
		}
	};
} );